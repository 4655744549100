/**
 * This file is part of the "Awaken Media" project.
 *
 * (c) 2020 - CanalPlus International
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */
import React from 'react';

import { FormattedProps } from '../types';
import { Wrapper, ServicesWrapper, Slider } from '../styles/components';
import Title from 'shared/components/presentational/Title';
import { Card } from './Card';
import { OneShopArrow } from 'shared/components/presentational/Slider/styles/OneShopComponents';
import ResponsiveHOC from 'shared/components/presentational/ResponsiveHOC';
import { ResponsiveHocProps } from 'shared/components/presentational/ResponsiveHOC/ResponsiveHoc';

type Props = FormattedProps;

const mininumSliderItemsCount = 4;

function OneShopService({
  title: { htmlTag, label },
  subtitle,
  services,
  mediaQueries: { toPhablet }
}: Props & ResponsiveHocProps) {
  const renderCard = React.useCallback(
    ({ icon, description, information, link }, index) => (
      <Card
        data-index={index}
        key={index}
        description={description}
        icon={icon}
        information={information}
        link={link}
      />
    ),
    []
  );

  return (
    <Wrapper data-testid="oneShop-services">
      <Title titleLabel={label} subtitle={subtitle} titleHtmlTag={htmlTag} />
      {toPhablet || services.length < mininumSliderItemsCount ? (
        <ServicesWrapper>{services.map(renderCard)}</ServicesWrapper>
      ) : (
        <Slider
          showDots={false}
          isCenterMode={false}
          isInfinite={false}
          initialSlide={0}
          nextArrow={<OneShopArrow name="ArrowLeftOneShop" />}
          prevArrow={<OneShopArrow name="ArrowLeftOneShop" />}
          slidesToShow={mininumSliderItemsCount}
        >
          {services.map(renderCard)}
        </Slider>
      )}
    </Wrapper>
  );
}

export default ResponsiveHOC(OneShopService);
