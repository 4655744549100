import React from 'react';

import { FormattedService } from '../types';
import {
  CardWrapper,
  ServicePicture,
  Description,
  Information,
  CustomIcon,
  InformationWrapper,
  ServicePicturePlaceholder,
  SlideWrapper
} from '../styles/components';
import Link from 'shared/components/presentational/CustomLink/CustomLink';

export const Card = ({
  icon,
  description,
  information,
  link,
  ...props
}: FormattedService) => {
  const renderCard = React.useCallback(
    () => (
      <SlideWrapper {...props}>
        <CardWrapper link={Boolean(link)}>
          {icon ? (
            <ServicePicture url={icon.image} alt={icon.alt} />
          ) : (
            <ServicePicturePlaceholder />
          )}
          <Description dangerouslySetInnerHTML={{ __html: description }} />
          {information && information.length > 0 ? (
            <InformationWrapper>
              <CustomIcon name="InfoOneShop" width="12px" height="12px" />
              <Information>{information}</Information>
            </InformationWrapper>
          ) : null}
        </CardWrapper>
      </SlideWrapper>
    ),
    [description, icon, information, link, props]
  );

  return link ? <Link to={link}>{renderCard()}</Link> : renderCard();
};
